.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: black;
}

.loading {
  font-size: 5vh;

  .loading-text,
  .click-text {
    margin-bottom: 0.5em;
  }

  .click-text {
    display: none;
  }
  &.loading-done {
    .loading-text {
      display: none;
    }
    .click-text {
      display: block;
    }
  }

  .tile.spinning {
    position: relative;
    .black,
    .white {
      animation-duration: 1.0s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    .black {
      animation-name: black-tile-spinning;
      @keyframes black-tile-spinning {
        0% { transform: perspective(5em) rotate3d(1, -1, 0, 0deg); }
        100% { transform: perspective(5em) rotate3d(1, -1, 0, 360deg); }
      }
    }
    .white {
      animation-name: white-tile-spinning;
      @keyframes white-tile-spinning {
        0% { transform: perspective(5em) rotate3d(1, -1, 0, -180deg); }
        100% { transform: perspective(5em) rotate3d(1, -1, 0, 180deg); }
      }
    }
  }
}

.main {
  position: relative;
  z-index: 0;
  background-image:
    radial-gradient(circle farthest-corner, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8)),
    linear-gradient(to bottom, $white, $black);
  background-size: 100% 100%;
  overflow: hidden;
}

.screen-flash {
  background-color: #fff;
  z-index: 1000;
}

.infobox {
  position: absolute;
  padding: 0.1em 0.2em 0.2em;

  // Pull it out onto a layer so it doesn't need to repaint the background when
  // the info changes (which happens every move).
  transform: translateZ(0);

  font-size: 1em;
  line-height: 1.0;

  text-shadow: 0 0.05em 0.3em rgba(0, 0, 0, 0.7);

  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }
  &.left {
    left: 0;
    text-align: left;
  }
  &.right {
    right: 0;
    text-align: right;
  }
}

.info-label {
  font-size: 0.3em;
  line-height: 1.0;
  opacity: 0.7;
  text-transform: uppercase;
}

.info {
  color: #fff;
  font-weight: 700;
}

.button {
  display: block;
  outline: 0;

  &:not(.disabled),
  &:not(.disabled) * {
    cursor: pointer;
  }
}

.hud-button {
  // Embiggen hit box
  margin: -0.3em;
  padding: 0.3em;

  font-size: 1em;
  line-height: 1.0;
  color: #eeeeee;
  text-shadow: 0 0.05em 0.3em rgba(0, 0, 0, 0.7);

  transform: translateZ(0); // Force a new layer for speedy transforms
  transition: transform 0.1s ease-out, opacity 0.2s ease-in-out;

  &:not(.disabled):hover,
  &:not(.disabled):focus {
    color: #cccccc;
  }
  &:not(.disabled):active {
    color: #aaaaaa;
    // For some reason, this makes the menu button respons extremely slowly on
    // Chrome. If I move it a bit away from the right edge (e.g. by inserting
    // an &nbsp; after it), all is fine.
    // transform: translate(0.03em, 0.03em) translateZ(0);
  }

  &.disabled {
    color: #888888;
  }
}

@keyframes flip-in {
  from {
    transform: perspective(5rem) rotate3d(1, 0, 0, -90deg);
  }
  to {
    transform: perspective(5rem) rotate3d(1, 0, 0, 0deg);
  }
}
@keyframes flip-out {
  from {
    transform: perspective(5rem) rotate3d(1, 0, 0, 0deg);
  }
  to {
    transform: perspective(5rem) rotate3d(1, 0, 0, 90deg);
  }
}

.tile-button {
  box-sizing: border-box;
  font-size: 0.7em;
  width: 100%;
  height: calc(1rem - #{2 * $tile-gap}); // Why times 2?
  line-height: calc(0.87 * (1rem - #{2 * $tile-gap}));
  margin: $tile-gap 0;
  text-align: center;
  box-shadow: 0.05em 0.05em 0.3em rgba(0, 0, 0, 0.7);
  overflow: hidden;

  animation-name: flip-out;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  .flip-in & {
    animation-name: flip-in;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
  }
  &.no-flip {
    animation-name: none;
  }

  &.white {
    @include gradient-background($white);
    color: $black;
    text-shadow: 0 0.05em 0.3em rgba(0, 0, 0, 0.7);
    &:hover,
    &:focus {
      color: lighten($black, 10%);
    }
    &:active {
      color: lighten($black, 20%);
    }
  }
  &.black {
    @include gradient-background($black);
    color: darken($white, 10%);
    text-shadow: 0 0.05em 0.3em rgba(255, 255, 255, 0.7);
    &:hover,
    &:focus {
      color: darken($white, 20%);
    }
    &:active {
      color: darken($white, 30%);
    }
  }
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  0.001% {
    visibility: visible;
  }
  to {
    opacity: 1;
  }
}

.on-board {
  position: absolute;
  width: $board-width - $tile-gap;
  height: $board-height - $tile-gap;
  left: -($board-width - $tile-gap) / 2;
  top: -($board-height - $tile-gap) / 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.below-board {
  position: absolute;
  width: $board-width - $tile-gap;
  left: -($board-width - $tile-gap) / 2;
  top: ($board-height + $tile-gap) / 2 + 1em;
}

.game-over {
  width: $board-width;
  text-align: center;
}

.game-over-text {
  padding-bottom: 0.2em; // Account for extra space at top compared to bottom.
  font-size: 1.3em;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.0;
  color: $black;
  text-shadow: 0 0.02em 0.15em rgba(255, 255, 255, 1.0);

  animation-name: game-over-spin-in;
  animation-duration: 2.5s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: both;

  @keyframes game-over-spin-in {
    0% {
      transform: perspective(5rem) rotate3d(1, 0, 0, -90deg - 10 * 360deg);
    }
    100% {
      transform: perspective(5rem) rotate3d(1, 0, 0, 0deg);
    }
  }
}

.continue-button {
  .flip-in & {
    animation-delay: 3.0s;
  }
}

.menu {
  padding: 0 calc((100% - #{$board-width - $tile-gap}) / 2);
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out 0.2s, visibility 0.7s;
  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-out, visibility 0s;
  }
}

.menu-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: radial-gradient(circle farthest-corner, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
}

.menu-tile-button {
  @for $i from 1 to 7 {
    .flip-in &:nth-child(#{$i}) {
      animation-delay: 0.1s * ($i - 1);
    }
  }
}

.check-mark {
  display: inline-block;
  position: relative;
  width: 0.7em;
  height: 0.7em;
  vertical-align: baseline;
  margin-right: 0.4em;

  &::before,
  &::after {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 0.3em;
    backface-visibility: hidden;
    .white & {
      color: $white;
      @include gradient-background($black);
    }
    .black & {
      color: $black;
      @include gradient-background($white);
    }
    transition: transform 0.3s ease-in-out;
  }

  // The :before element is on the "front" side and used for the unchecked state.
  &::before {
    content: ' ';
    transform: perspective(2rem) rotate3d(1, -1, 0, 0deg);
  }
  &.checked::before {
    transform: perspective(2rem) rotate3d(1, -1, 0, -180deg);
  }

  // The :after element is on the "back" side and used for the checked state.
  &::after {
    content: 'x';
    transform: perspective(2rem) rotate3d(1, -1, 0, 180deg) scale(-1, 1);
  }
  &.checked::after {
    transform: perspective(2rem) rotate3d(1, -1, 0, 0deg) scale(-1, 1);
  }
}
