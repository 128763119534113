.game-center {
  position: absolute;
  left: 50%;
  top: 50%;
}

.board {
  position: absolute;
  cursor: pointer;
  & * {
    cursor: pointer;
  }
}

// From the Material Design 2014 colour palette: https://material.io/design/color/
$order-colors: (
  1: #3F51B5,
  2: #03A9F4,
  3: #009688,
  4: #8BC34A,
  5: #FFEB3B,
  6: #FF9800,
  7: #F44336,
  8: #E91E63,
  9: #9C27B0,
);

.tile {
  width: 1em;
  height: 1em;
}

.tile-inner {
  position: absolute;
  left: $tile-gap / 2;
  top: $tile-gap / 2;
  width: 1em - $tile-gap;
  height: 1em - $tile-gap;

  .board-tile & {
    box-shadow: 0.05em 0.05em 0.3em rgba(0, 0, 0, 0.7);
    background-repeat: no-repeat;
    backface-visibility: hidden;
    &.white {
      @include gradient-background($white);
    }
    &.black {
      @include gradient-background($black);
    }
  }

  .block-tile & {
    box-shadow: 0.05em 0.05em 0.3em rgba(0, 0, 0, 0.7);
    background-repeat: no-repeat;
    @each $order, $color in $order-colors {
      &.order-#{$order} {
        @include gradient-background($color);
      }
    }
  }

  .ghost-tile & {
    left: -$tile-gap / 2;
    top: -$tile-gap / 2;
    border-radius: $tile-gap;
    border-width: $tile-gap;
    border-style: solid;
    @each $order, $color in $order-colors {
      &.order-#{$order} {
        border-color: $color;
        box-shadow: inset 0 0 0.3em $color;
      }
    }
  }
}

.ghost {
  .error {
    position: absolute;
    @each $pseudoclass, $angle in (before: -45deg, after: 45deg) {
      &:#{$pseudoclass} {
        content: ' ';
        $width: 0.75em;
        $height: 0.15em;
        display: block;
        position: absolute;
        left: 0.5em;
        top: 0.5em;
        width: $width;
        height: $height;
        margin-left: -$width / 2;
        margin-top: -$height / 2;
        transform: rotate($angle);
        $color: #f00;
        background-image: radial-gradient(circle farthest-corner, $color, darken($color, 6.25%) 50%, darken($color, 18.75%));
      }
    }
  }
}

.queue {
  position: absolute;
}

.queue-glow {
  position: absolute;
  z-index: -1;
  background-image: radial-gradient(circle closest-side,
    rgba(255, 255, 255, 0.80),
    rgba(255, 255, 255, 0.68) 20%,
    rgba(255, 255, 255, 0.42) 40%,
    rgba(255, 255, 255, 0.19) 60%,
    rgba(255, 255, 255, 0.06) 80%,
    rgba(255, 255, 255, 0.00));
}

.block {
  position: absolute;
  // Uncomment for debugging.
  /*
  border: 2.5em solid red;
  margin-left: -2.5em;
  margin-top: -2.5em;
  background: red;
  //*/
}

.handle {
  $size: 0.3em;
  position: absolute;
  width: $size;
  height: $size;
  margin-left: -$size / 2;
  margin-top: -$size / 2;

  border-radius: 50% 50%;
  @include gradient-background($black);
  box-shadow: 0 0 0.3em rgba(255, 255, 255, 1.0);
}

.stash {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  padding: 0.2em;
  border: 0.1em solid rgba(255, 255, 255, 0.7);
  border-radius: 0.1em;
  margin-left: -0.3em;
  margin-top: -0.3em;
  box-shadow: inset 0 0.05em 0.15em rgba(0, 0, 0, 0.2),
    0 0 0.2em rgba(0, 0, 0, 0.4);
  z-index: 10;

  cursor: pointer;
  & * {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    border: 0.1em solid rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.1);
  }

  &:active {
    border: 0.1em solid rgba(255, 255, 255, 1.0);
    background: transparent;
  }

  &:after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    content: 'Stash';
    text-align: center;
    font-size: 0.3em;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.score-bubble {
  font-weight: 700;
  line-height: 1;
  color: #ffffff;

  .score {
    position: absolute;
    margin-top: -0.1em; // Tweaked to make the middle line up.
    transform: translate(-50%, -50%) scale(1.0) translateZ(0);
    text-align: center;
    // font-size: 0.8em; // Set through JavaScript
    text-shadow: 0 0.05em 0.3em rgba(0, 0, 0, 0.7);
  }

  .bonus {
    font-size: 1.0em;
    margin-top: 0.07em;
  }
  .bonus-blackout {
    color: $black;
    text-shadow: 0 0 0.3em $white;
  }
  .bonus-whiteout {
    color: $white;
    text-shadow: 0 0 0.3em $black;
  }
}

@each $order, $color in $order-colors {
  .order-#{$order}-color {
    color: $color;
  }
  .order-#{$order}-color-light {
    color: lighten($color, 20%);
  }
}

.blinking {
  animation-name: blinking;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-timing-function: step-start;
  @keyframes blinking {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
  }
}

.hint {
  $size: 1.2em;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  width: $size;
  height: $size;
  margin-left: -$size / 2;
  margin-top: -$size / 2;
  
  border-radius: $size / 2;
  border: 0.2 * $size solid $white;
  box-shadow:
    0 0 0.3em rgba(0, 0, 0, 1.0),
    inset 0 0 0.3em rgba(0, 0, 0, 1.0);
}
