// Taken from:
// https://google-webfonts-helper.herokuapp.com/fonts/corben?subsets=latin

/* corben-regular - latin */
@font-face {
  font-family: 'Corben';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/corben-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Corben Regular'), local('Corben-Regular'),
       url('../fonts/corben-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/corben-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/corben-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/corben-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/corben-v12-latin-regular.svg#Corben') format('svg'); /* Legacy iOS */
}
/* corben-700 - latin */
@font-face {
  font-family: 'Corben';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/corben-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Corben'),
       url('../fonts/corben-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/corben-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/corben-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/corben-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/corben-v12-latin-700.svg#Corben') format('svg'); /* Legacy iOS */
}
