* {
  cursor: default;
  user-select: none;
}

html, body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Corben', sans-serif;
  color: #eeeeee;
}
