@font-face {
  font-family: 'icomoon';
  src: url("../icomoon/fonts/icomoon.eot");
  src: url("../icomoon/fonts/icomoon.eot#iefix") format("embedded-opentype"), url("../icomoon/fonts/icomoon.ttf") format("truetype"), url("../icomoon/fonts/icomoon.woff") format("woff"), url("../icomoon/fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-undo2:before {
  content: "\e912"; }

.icon-menu:before {
  content: "\e900"; }

/* corben-regular - latin */
@font-face {
  font-family: 'Corben';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/corben-v12-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Corben Regular"), local("Corben-Regular"), url("../fonts/corben-v12-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/corben-v12-latin-regular.woff2") format("woff2"), url("../fonts/corben-v12-latin-regular.woff") format("woff"), url("../fonts/corben-v12-latin-regular.ttf") format("truetype"), url("../fonts/corben-v12-latin-regular.svg#Corben") format("svg");
  /* Legacy iOS */ }

/* corben-700 - latin */
@font-face {
  font-family: 'Corben';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/corben-v12-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Corben"), url("../fonts/corben-v12-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/corben-v12-latin-700.woff2") format("woff2"), url("../fonts/corben-v12-latin-700.woff") format("woff"), url("../fonts/corben-v12-latin-700.ttf") format("truetype"), url("../fonts/corben-v12-latin-700.svg#Corben") format("svg");
  /* Legacy iOS */ }

* {
  cursor: default;
  user-select: none; }

html, body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Corben', sans-serif;
  color: #eeeeee; }

.fullscreen {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; }

.center-children {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.game-center {
  position: absolute;
  left: 50%;
  top: 50%; }

.board {
  position: absolute;
  cursor: pointer; }
  .board * {
    cursor: pointer; }

.tile {
  width: 1em;
  height: 1em; }

.tile-inner {
  position: absolute;
  left: 0.025em;
  top: 0.025em;
  width: 0.95em;
  height: 0.95em; }
  .board-tile .tile-inner {
    box-shadow: 0.05em 0.05em 0.3em rgba(0, 0, 0, 0.7);
    background-repeat: no-repeat;
    backface-visibility: hidden; }
    .board-tile .tile-inner.white {
      background-image: radial-gradient(farthest-corner at 40% 40%, #ffffff, #f7f7f7 50%, #e8e8e8 75%, #d1d1d1 100%); }
    .board-tile .tile-inner.black {
      background-image: radial-gradient(farthest-corner at 40% 40%, #222222, #1a1a1a 50%, #0b0b0b 75%, black 100%); }
  .block-tile .tile-inner {
    box-shadow: 0.05em 0.05em 0.3em rgba(0, 0, 0, 0.7);
    background-repeat: no-repeat; }
    .block-tile .tile-inner.order-1 {
      background-image: radial-gradient(farthest-corner at 40% 40%, #3F51B5, #3b4caa 50%, #334293 75%, #273371 100%); }
    .block-tile .tile-inner.order-2 {
      background-image: radial-gradient(farthest-corner at 40% 40%, #03A9F4, #039fe5 50%, #028ac7 75%, #026a99 100%); }
    .block-tile .tile-inner.order-3 {
      background-image: radial-gradient(farthest-corner at 40% 40%, #009688, #00877a 50%, #00685e 75%, #003a35 100%); }
    .block-tile .tile-inner.order-4 {
      background-image: radial-gradient(farthest-corner at 40% 40%, #8BC34A, #84bf3f 50%, #74a838 75%, #5c852c 100%); }
    .block-tile .tile-inner.order-5 {
      background-image: radial-gradient(farthest-corner at 40% 40%, #FFEB3B, #ffe92c 50%, #ffe60d 75%, #dec800 100%); }
    .block-tile .tile-inner.order-6 {
      background-image: radial-gradient(farthest-corner at 40% 40%, #FF9800, #f08f00 50%, #d17d00 75%, #a36100 100%); }
    .block-tile .tile-inner.order-7 {
      background-image: radial-gradient(farthest-corner at 40% 40%, #F44336, #f33527 50%, #ef1d0d 75%, #c4170b 100%); }
    .block-tile .tile-inner.order-8 {
      background-image: radial-gradient(farthest-corner at 40% 40%, #E91E63, #e2165b 50%, #c61350 75%, #9c0f3f 100%); }
    .block-tile .tile-inner.order-9 {
      background-image: radial-gradient(farthest-corner at 40% 40%, #9C27B0, #9124a3 50%, #7b1f8a 75%, #591665 100%); }
  .ghost-tile .tile-inner {
    left: -0.025em;
    top: -0.025em;
    border-radius: 0.05em;
    border-width: 0.05em;
    border-style: solid; }
    .ghost-tile .tile-inner.order-1 {
      border-color: #3F51B5;
      box-shadow: inset 0 0 0.3em #3F51B5; }
    .ghost-tile .tile-inner.order-2 {
      border-color: #03A9F4;
      box-shadow: inset 0 0 0.3em #03A9F4; }
    .ghost-tile .tile-inner.order-3 {
      border-color: #009688;
      box-shadow: inset 0 0 0.3em #009688; }
    .ghost-tile .tile-inner.order-4 {
      border-color: #8BC34A;
      box-shadow: inset 0 0 0.3em #8BC34A; }
    .ghost-tile .tile-inner.order-5 {
      border-color: #FFEB3B;
      box-shadow: inset 0 0 0.3em #FFEB3B; }
    .ghost-tile .tile-inner.order-6 {
      border-color: #FF9800;
      box-shadow: inset 0 0 0.3em #FF9800; }
    .ghost-tile .tile-inner.order-7 {
      border-color: #F44336;
      box-shadow: inset 0 0 0.3em #F44336; }
    .ghost-tile .tile-inner.order-8 {
      border-color: #E91E63;
      box-shadow: inset 0 0 0.3em #E91E63; }
    .ghost-tile .tile-inner.order-9 {
      border-color: #9C27B0;
      box-shadow: inset 0 0 0.3em #9C27B0; }

.ghost .error {
  position: absolute; }
  .ghost .error:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0.5em;
    top: 0.5em;
    width: 0.75em;
    height: 0.15em;
    margin-left: -0.375em;
    margin-top: -0.075em;
    transform: rotate(-45deg);
    background-image: radial-gradient(circle farthest-corner, #f00, #df0000 50%, #9f0000); }
  .ghost .error:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0.5em;
    top: 0.5em;
    width: 0.75em;
    height: 0.15em;
    margin-left: -0.375em;
    margin-top: -0.075em;
    transform: rotate(45deg);
    background-image: radial-gradient(circle farthest-corner, #f00, #df0000 50%, #9f0000); }

.queue {
  position: absolute; }

.queue-glow {
  position: absolute;
  z-index: -1;
  background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.68) 20%, rgba(255, 255, 255, 0.42) 40%, rgba(255, 255, 255, 0.19) 60%, rgba(255, 255, 255, 0.06) 80%, rgba(255, 255, 255, 0)); }

.block {
  position: absolute;
  /*
  border: 2.5em solid red;
  margin-left: -2.5em;
  margin-top: -2.5em;
  background: red;
  //*/ }

.handle {
  position: absolute;
  width: 0.3em;
  height: 0.3em;
  margin-left: -0.15em;
  margin-top: -0.15em;
  border-radius: 50% 50%;
  background-image: radial-gradient(farthest-corner at 40% 40%, #222222, #1a1a1a 50%, #0b0b0b 75%, black 100%);
  box-shadow: 0 0 0.3em white; }

.stash {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  padding: 0.2em;
  border: 0.1em solid rgba(255, 255, 255, 0.7);
  border-radius: 0.1em;
  margin-left: -0.3em;
  margin-top: -0.3em;
  box-shadow: inset 0 0.05em 0.15em rgba(0, 0, 0, 0.2), 0 0 0.2em rgba(0, 0, 0, 0.4);
  z-index: 10;
  cursor: pointer; }
  .stash * {
    cursor: pointer; }
  .stash:hover, .stash:focus {
    border: 0.1em solid rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.1); }
  .stash:active {
    border: 0.1em solid white;
    background: transparent; }
  .stash:after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: 'Stash';
    text-align: center;
    font-size: 0.3em;
    color: #ffffff;
    text-transform: uppercase; }

.score-bubble {
  font-weight: 700;
  line-height: 1;
  color: #ffffff; }
  .score-bubble .score {
    position: absolute;
    margin-top: -0.1em;
    transform: translate(-50%, -50%) scale(1) translateZ(0);
    text-align: center;
    text-shadow: 0 0.05em 0.3em rgba(0, 0, 0, 0.7); }
  .score-bubble .bonus {
    font-size: 1.0em;
    margin-top: 0.07em; }
  .score-bubble .bonus-blackout {
    color: #222222;
    text-shadow: 0 0 0.3em #ffffff; }
  .score-bubble .bonus-whiteout {
    color: #ffffff;
    text-shadow: 0 0 0.3em #222222; }

.order-1-color {
  color: #3F51B5; }

.order-1-color-light {
  color: #8591d5; }

.order-2-color {
  color: #03A9F4; }

.order-2-color-light {
  color: #60ccfd; }

.order-3-color {
  color: #009688; }

.order-3-color-light {
  color: #00fce4; }

.order-4-color {
  color: #8BC34A; }

.order-4-color-light {
  color: #bcdc97; }

.order-5-color {
  color: #FFEB3B; }

.order-5-color-light {
  color: #fff5a1; }

.order-6-color {
  color: #FF9800; }

.order-6-color-light {
  color: #ffc166; }

.order-7-color {
  color: #F44336; }

.order-7-color-light {
  color: #f99d97; }

.order-8-color {
  color: #E91E63; }

.order-8-color-light {
  color: #f27ba3; }

.order-9-color {
  color: #9C27B0; }

.order-9-color-light {
  color: #ca61dc; }

.blinking {
  animation-name: blinking;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-timing-function: step-start; }

@keyframes blinking {
  0% {
    opacity: 1.0; }
  50% {
    opacity: 0.0; } }

.hint {
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  width: 1.2em;
  height: 1.2em;
  margin-left: -0.6em;
  margin-top: -0.6em;
  border-radius: 0.6em;
  border: 0.24em solid #ffffff;
  box-shadow: 0 0 0.3em black, inset 0 0 0.3em black; }

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: black; }

.loading {
  font-size: 5vh; }
  .loading .loading-text,
  .loading .click-text {
    margin-bottom: 0.5em; }
  .loading .click-text {
    display: none; }
  .loading.loading-done .loading-text {
    display: none; }
  .loading.loading-done .click-text {
    display: block; }
  .loading .tile.spinning {
    position: relative; }
    .loading .tile.spinning .black,
    .loading .tile.spinning .white {
      animation-duration: 1.0s;
      animation-iteration-count: infinite;
      animation-timing-function: linear; }
    .loading .tile.spinning .black {
      animation-name: black-tile-spinning; }

@keyframes black-tile-spinning {
  0% {
    transform: perspective(5em) rotate3d(1, -1, 0, 0deg); }
  100% {
    transform: perspective(5em) rotate3d(1, -1, 0, 360deg); } }
    .loading .tile.spinning .white {
      animation-name: white-tile-spinning; }

@keyframes white-tile-spinning {
  0% {
    transform: perspective(5em) rotate3d(1, -1, 0, -180deg); }
  100% {
    transform: perspective(5em) rotate3d(1, -1, 0, 180deg); } }

.main {
  position: relative;
  z-index: 0;
  background-image: radial-gradient(circle farthest-corner, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), linear-gradient(to bottom, #ffffff, #222222);
  background-size: 100% 100%;
  overflow: hidden; }

.screen-flash {
  background-color: #fff;
  z-index: 1000; }

.infobox {
  position: absolute;
  padding: 0.1em 0.2em 0.2em;
  transform: translateZ(0);
  font-size: 1em;
  line-height: 1.0;
  text-shadow: 0 0.05em 0.3em rgba(0, 0, 0, 0.7); }
  .infobox.top {
    top: 0; }
  .infobox.bottom {
    bottom: 0; }
  .infobox.left {
    left: 0;
    text-align: left; }
  .infobox.right {
    right: 0;
    text-align: right; }

.info-label {
  font-size: 0.3em;
  line-height: 1.0;
  opacity: 0.7;
  text-transform: uppercase; }

.info {
  color: #fff;
  font-weight: 700; }

.button {
  display: block;
  outline: 0; }
  .button:not(.disabled),
  .button:not(.disabled) * {
    cursor: pointer; }

.hud-button {
  margin: -0.3em;
  padding: 0.3em;
  font-size: 1em;
  line-height: 1.0;
  color: #eeeeee;
  text-shadow: 0 0.05em 0.3em rgba(0, 0, 0, 0.7);
  transform: translateZ(0);
  transition: transform 0.1s ease-out, opacity 0.2s ease-in-out; }
  .hud-button:not(.disabled):hover, .hud-button:not(.disabled):focus {
    color: #cccccc; }
  .hud-button:not(.disabled):active {
    color: #aaaaaa; }
  .hud-button.disabled {
    color: #888888; }

@keyframes flip-in {
  from {
    transform: perspective(5rem) rotate3d(1, 0, 0, -90deg); }
  to {
    transform: perspective(5rem) rotate3d(1, 0, 0, 0deg); } }

@keyframes flip-out {
  from {
    transform: perspective(5rem) rotate3d(1, 0, 0, 0deg); }
  to {
    transform: perspective(5rem) rotate3d(1, 0, 0, 90deg); } }

.tile-button {
  box-sizing: border-box;
  font-size: 0.7em;
  width: 100%;
  height: calc(1rem - 0.1em);
  line-height: calc(0.87 * (1rem - 0.1em));
  margin: 0.05em 0;
  text-align: center;
  box-shadow: 0.05em 0.05em 0.3em rgba(0, 0, 0, 0.7);
  overflow: hidden;
  animation-name: flip-out;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both; }
  .flip-in .tile-button {
    animation-name: flip-in;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both; }
  .tile-button.no-flip {
    animation-name: none; }
  .tile-button.white {
    background-image: radial-gradient(farthest-corner at 40% 40%, #ffffff, #f7f7f7 50%, #e8e8e8 75%, #d1d1d1 100%);
    color: #222222;
    text-shadow: 0 0.05em 0.3em rgba(0, 0, 0, 0.7); }
    .tile-button.white:hover, .tile-button.white:focus {
      color: #3c3c3c; }
    .tile-button.white:active {
      color: #555555; }
  .tile-button.black {
    background-image: radial-gradient(farthest-corner at 40% 40%, #222222, #1a1a1a 50%, #0b0b0b 75%, black 100%);
    color: #e6e6e6;
    text-shadow: 0 0.05em 0.3em rgba(255, 255, 255, 0.7); }
    .tile-button.black:hover, .tile-button.black:focus {
      color: #cccccc; }
    .tile-button.black:active {
      color: #b3b3b3; }

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0; }
  0.001% {
    visibility: visible; }
  to {
    opacity: 1; } }

.on-board {
  position: absolute;
  width: 4.95em;
  height: 4.95em;
  left: -2.475em;
  top: -2.475em;
  display: flex;
  align-items: center;
  justify-content: center; }

.below-board {
  position: absolute;
  width: 4.95em;
  left: -2.475em;
  top: 3.525em; }

.game-over {
  width: 5em;
  text-align: center; }

.game-over-text {
  padding-bottom: 0.2em;
  font-size: 1.3em;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.0;
  color: #222222;
  text-shadow: 0 0.02em 0.15em white;
  animation-name: game-over-spin-in;
  animation-duration: 2.5s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: both; }

@keyframes game-over-spin-in {
  0% {
    transform: perspective(5rem) rotate3d(1, 0, 0, -3690deg); }
  100% {
    transform: perspective(5rem) rotate3d(1, 0, 0, 0deg); } }

.flip-in .continue-button {
  animation-delay: 3.0s; }

.menu {
  padding: 0 calc((100% - 4.95em) / 2);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out 0.2s, visibility 0.7s; }
  .menu.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-out, visibility 0s; }

.menu-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: radial-gradient(circle farthest-corner, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)); }

.flip-in .menu-tile-button:nth-child(1) {
  animation-delay: 0s; }

.flip-in .menu-tile-button:nth-child(2) {
  animation-delay: 0.1s; }

.flip-in .menu-tile-button:nth-child(3) {
  animation-delay: 0.2s; }

.flip-in .menu-tile-button:nth-child(4) {
  animation-delay: 0.3s; }

.flip-in .menu-tile-button:nth-child(5) {
  animation-delay: 0.4s; }

.flip-in .menu-tile-button:nth-child(6) {
  animation-delay: 0.5s; }

.check-mark {
  display: inline-block;
  position: relative;
  width: 0.7em;
  height: 0.7em;
  vertical-align: baseline;
  margin-right: 0.4em; }
  .check-mark::before, .check-mark::after {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 0.3em;
    backface-visibility: hidden;
    transition: transform 0.3s ease-in-out; }
    .white .check-mark::before, .white .check-mark::after {
      color: #ffffff;
      background-image: radial-gradient(farthest-corner at 40% 40%, #222222, #1a1a1a 50%, #0b0b0b 75%, black 100%); }
    .black .check-mark::before, .black .check-mark::after {
      color: #222222;
      background-image: radial-gradient(farthest-corner at 40% 40%, #ffffff, #f7f7f7 50%, #e8e8e8 75%, #d1d1d1 100%); }
  .check-mark::before {
    content: ' ';
    transform: perspective(2rem) rotate3d(1, -1, 0, 0deg); }
  .check-mark.checked::before {
    transform: perspective(2rem) rotate3d(1, -1, 0, -180deg); }
  .check-mark::after {
    content: 'x';
    transform: perspective(2rem) rotate3d(1, -1, 0, 180deg) scale(-1, 1); }
  .check-mark.checked::after {
    transform: perspective(2rem) rotate3d(1, -1, 0, 0deg) scale(-1, 1); }
