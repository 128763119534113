.generator-display {
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  font-size: 10px;
  padding: 50px;
}

.generator-control-wrapper {
  line-height: 3em;
}

.generator-control-label {
  display: inline-block;
  width: 10em;
}

.generator-control {
  width: 20em;
  vertical-align: middle;
}

.generator-value {
  display: inline-block;
  text-align: right;
  width: 5em;
}

.generator-display-bar {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 10em;
}

.generator-display-bar-label {
  color: #ffffff;
  text-align: center;
  line-height: 1em;
  width: 1em;
  height: 1em;
}
