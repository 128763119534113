@mixin gradient-background($color) {
  background-image: radial-gradient(
    farthest-corner at 40% 40%,
    $color,
    darken($color, 3%) 50%,
    darken($color, 9%) 75%,
    darken($color, 18%) 100%);
}

.fullscreen {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.center-children {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
